<template>
	<div class="">
		<!-- tabs -->
		<v-tabs v-model="tab" @change="isSearch = false; reset()">
			<v-tab >
				Customer report
			</v-tab>
			<v-tab>
				Material report
			</v-tab>
		</v-tabs>
		<v-divider  />
		<!-- filter -->
		<v-row class="mt-1 mx-1 mb-1">
			<!-- city region filter -->
			<v-col lg="3" md="5" sm="7" cols="12" class="px-1 pb-0">
				<v-row class="pa-3 pa-3 d-flex justify-space-between">
					<!-- cities -->
					<v-col cols="5" class="pa-0 pe-1 d-flex">
						<v-icon class="me-2" size="28"> mdi-map-marker-multiple </v-icon>
						<v-autocomplete
							v-model="cityId"
							:items="cities"
							item-text="name"
							item-value="id"
							label="City"
							dense
							hide-details
							append-icon
							@change="setRegions(); searchCityId = null"
							:disabled="loading"
							outlined
							multiple
							:search-input.sync="searchCityId"
						>
							<template v-slot:prepend-item>
								<v-list-item
									ripple
									@click="toggleCities"
								>
									<v-list-item-action>
										<v-icon>
											{{ iconCities }}
										</v-icon>
									</v-list-item-action>
									
									<v-list-item-content>
										<v-list-item-title>
											Select All
										</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
								<v-divider class="mt-2"></v-divider>
							</template>

							<template v-slot:selection="{ item, index }">
								<span v-if="index === 0" class="me-1">{{ item.name }}</span>
								<span
									v-if="index === 1"
									class="grey--text text-caption"
								>
									(+{{ cityId.length - 1 }})
								</span>
							</template>   
						</v-autocomplete>
					</v-col>

					<!-- region -->
					<v-col cols="7" class="pa-0">
						<v-autocomplete
							v-model="regionIds"
							:items="regionsAll.filter(c => cityId.includes(c.cityId))"
							item-text="name"
							item-value="id"
							label="Regions"
							dense
							multiple
							clearable
							hide-details
							:loading="regionsLoading"
							:disabled="loading || regionsLoading || cityId === []"
							outlined
							:search-input.sync="searchRegionId"
							@change="searchRegionId = null"
						>
							<template v-slot:prepend-item v-if="regions">
								<v-list-item ripple @click="toggleReignsFilterItems">
									<v-list-item-action>
										<v-icon>
											{{ icon }}
										</v-icon>
									</v-list-item-action>
									
									<v-list-item-content>
										<v-list-item-title>
											Select All
										</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
								<v-divider class="mt-2"></v-divider>
							</template>
							<template v-slot:selection="{ item, index }">
								<span v-if="index === 0" class="me-1">{{ item.name }}</span>
								<span
									v-if="index === 1"
									class="grey--text text-caption"
								>
									(+{{ regionIds.length - 1 }})
								</span>
							</template>
						</v-autocomplete>
					</v-col>
				</v-row>
			</v-col>

			<!-- materials -->
			<v-col v-if="tab === 0" cols="12" sm="3"  md="2"  class="px-1 pb-0">
				<v-autocomplete 
					v-model="materialId"
					:items="materials"
					item-text="name"
					item-value="id"
					:label="$t('inputs.item')"
					outlined
					hide-details
					dense
					:disabled="loading || submitLoading"
					:loading="loading"
				/>
			</v-col>

			<!-- type account -->
			<v-col cols="12" sm="3" md="2" class="px-1 pb-0">
				<v-autocomplete 
					v-model="type"
					:items="entityTypeArray"
					item-text="name"
					item-value="id"
					:label="$t('inputs.type')"
					outlined
					hide-details
					dense
					:disabled="loading || submitLoading"
					:loading="loading"
					@change="fetchAccountCustomerByType()"
				/>
			</v-col>

			<!-- accounts -->
			<v-col cols="12" sm="3" md="2" class="px-1 pb-0">
				<v-autocomplete 
					v-model="accountId"
					:items="customers"
					item-text="name"
					item-value="accountId"
					:label="$t('inputs.account')"
					outlined
					hide-details
					dense
					:disabled="submitLoading"
					:loading="loading || submitLoading"
				/>
			</v-col>

			<!-- classifications -->
			<v-col cols="12" sm="3" md="2" class="px-1 pb-0">
				<v-autocomplete
					label="Workload"
					v-model="workloadIds"
					:items="classification"
					item-text="name"
					item-value="id"
					clearable
					outlined
					dense
					hide-details="auto"
					multiple
				></v-autocomplete>
			</v-col>

			<v-col cols="12" sm="3" md="2" class="px-1 pb-0">
				<!-- date -->
				<bee-date-picker
					v-model="fromDate" 
					input-label="From Date"
					elevation="2"
					menu-picker
					dense
					hide-details
					:input-props="{
						outlined: true,
						dense: true,
						loading: submitLoading || loading
					}"
					min="1940-01-01"
					:disabled="loading || submitLoading"
				/>
			</v-col>

			<!-- to date -->
			<v-col cols="12" sm="3" md="2" class="px-1 pb-0"> 
				<!-- date -->
				<bee-date-picker
					v-model="toDate" 
					input-label="To Date"
					elevation="2"
					menu-picker
					dense
					hide-details
					:input-props="{
						outlined: true,
						dense: true,
						loading: submitLoading || loading
					}"
					min="1940-01-01"
					:disabled="loading || Boolean($route.query.preview) || submitLoading"
				/>
			</v-col>

			<!-- types -->
			<!-- <v-col cols="12" sm="3" md="2" class="px-1 pb-0">
				<v-autocomplete 
					v-model="type"
					:items="[]"
					item-text="name"
					item-value="id"
					label="Type"
					outlined
					hide-details
					dense
					:disabled="loading || submitLoading"
					:loading="loading"
				/>
			</v-col> -->

			<!-- actions -->
			<v-spacer />
			<v-col cols="auto" class="px-0 px-md-3 pb-0">
				<v-btn
					color="success"
					class="px-sm-4 mx-2"
					:loading="loading"
					:disabled="loading || !accountId"
					small
					@click="isSearch = true; getStatistics()"
				>
					<v-icon> mdi-magnify </v-icon>
					{{ $t('search') }}
				</v-btn>

				<v-btn
					color="alert-color white--text"
					class="px-sm-8"
					:disabled="loading || !isSearch"
					small
					@click="reset()"
				>
					{{ $t('cancel') }}
				</v-btn>
			</v-col>
		</v-row>
		
		<bee-handy-table
			v-if="isSearch"
			:items="items"
			:headers="headers"
			class="mt-4"
			dense
			:items-per-page="-1"
			zebra
			:height="$vuetify.breakpoint.height - 210"
			hide-default-footer
		></bee-handy-table>
		<div
			v-if="!isSearch"
			class="d-flex flex-column justify-center align-center"
			:style="{ height: `${$vuetify.breakpoint.height - 210}px` }"
		>
			<template>
				<v-icon size="60" class="mb-1">mdi-invoice-list</v-icon>
				<span class="text-h6">{{$t('WarehousesStatistics.please-select-account-filter')}}</span>
			</template>
		</div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import moment from 'moment'
import { entityTypeArray } from '@/helpers/enums';
export default {
	data () {
		return {
			// filter
			cityId: [],
			regionIds: [],
			fromDate: null,
			toDate: null,
			type: 1,
			accountId: null,
			materialId: null,
			workloadIds: [],
			searchCityId: null,
			searchRegionId: null,
			accounts: [],

			loading: false,
			submitLoading: false,
			regionsLoading: false,

			items: [],
			staff: [],
			customers: [],
			entityTypeArray,
			isSearch: false,
			tab: 0
		}
	},

	computed: {
		...mapState({
			cities: state => state.cities.cities,
			regionsAll: state => state.regions.regionsAll,
			regions: state => state.regions.regions,
			materials: state => state.materials.materials,
			classification: state => state.workload.classification
		}),

		...mapGetters({
			getMaterialById: 'materials/getMaterialById'
		}),

		isAllFieldsEmpty(){
            return !this.regionIds.length === 0 && !this.fromDate && !this.toDate && !this.type && !this.accountId
        },

		iconCities () {
            if (this.selectedAllCities) return 'mdi-close-box'
            return 'mdi-checkbox-blank-outline'
        },

		selectedAllCities () {
            return this.cityId.length === this.cities.length;
        },
	
		selectedAllRegions () {
            return this.regionIds.length === this.regionsAll.filter(c => this.cityId?.includes(c.cityId)).length;
        },

		icon () {
            if (this.selectedAllRegions) return 'mdi-close-box'
            return 'mdi-checkbox-blank-outline'
        },

		headers () {
			const headers = [
				
				{
					text: 'الكمية الداخلة',
					name: 'in',
					value: 'totalIn'
				},
				{
					text: 'الكمية الخارجة',
					name: 'out',
					value: 'totalOut'
				},

				{
					text: 'تاريخ آخر مناقلة',
					name: 'lastCreatedAt',
					value: 'lastCreatedAt',
					setValueExpr: val => moment(val).format('YYYY-MM-DD')
				},

				{
					text: 'مستلم من ',
					name: 'fromAccountIds',
					value: 'fromAccountIds',
					setValueExpr: val => val.map(c => this.staff.find(s => s.accountId === c)?.name).join(', ')
				}
			]
			if (this.tab === 0) {
				headers.unshift(
					{
						text: 'الحساب',
						name: 'account',
						value: 'accountId',
						setValueExpr: val => this.customers.find(c => c.accountId === val)?.name
					},
				)
			} else {
				headers.unshift(
					{
						text: 'المادة',
						name: 'materials',
						value: 'materialId',
						setValueExpr: val => this.getMaterialById(val)?.name
					},
				)
			}
			return headers
		}	
	},

	methods: {
		moment,
		toggleCities () {
            this.$nextTick(() => {
                if (this.selectedAllCities) {
                    this.cityId = []
                    this.regionIds = []
                } 
                else {
                    this.cityId = this.cities.map(c => c.id);
                    this.setRegions()
                }
            })
        },

		setRegions () {
            this.regionIds = [];
            if (!this.cityId) return;
            this.regionsAll.forEach(region => {
                if (this.cityId.includes(region.cityId)) {
                    this.regionIds.push(region.id);
                }
            });
        },

		toggleReignsFilterItems () {
            this.$nextTick(() => {
                if (this.selectedAllRegions) {
                    this.regionIds = []
                } else {
                    this.regionIds = this.regionsAll.filter(c => this.cityId.includes(c.cityId)).map(c => c.id);
                }
            })
        },

		getStatistics() {
			this.loading = true
			const actionName = this.tab === 0 ? 'fetchStatisticsWarehouse' : 'fetchStatisticsMaterials'
			this.$store.dispatch(`statistics/${actionName}`, {
				regionIds: this.regionIds,
				materialId: this.materialId, 
				fromDate: this.fromDate, 
				toDate: this.toDate,
				accountId: this.accountId,
				workloadIds: this.workloadIds,
				types: this.type
			}).then((data) => {
				this.items = data
			}).finally(() => {
				this.loading = false
			})
		},

		fetchAccountCustomerByType () {
			this.submitLoading = true
			this.$store.dispatch('accounts/fetchAllBrief', { type: 1, entityType: this.type }).then((data) => {
				this.customers = data
			}).finally(() => {
				this.submitLoading = false
			})
		},

		reset () {
			this.cityId = []
			this.regionIds = []
			this.fromDate = null
			this.toDate = null
			this.type = 1
			this.accountId = null
			this.materialId = null
			this.isSearch = false
			this.workloadId = null
		}
	},

	created () {
		this.loading = true
		Promise.all([
			!this.cities.length ? this.$store.dispatch('cities/fetchCityMine') : null,
			!this.regionsAll.length ? this.$store.dispatch('regions/fetchAllRegions', {}) : null,
			!this.materials.length ? this.$store.dispatch('materials/fetchAll') : null,
			!this.classification.length ? this.$store.dispatch('workload/fetchAll') : null,
			this.$store.dispatch('accounts/fetchAllBrief', { type: 0 }).then((data) => {
				this.staff = data
			}),
			this.$store.dispatch('accounts/fetchAllBrief', { type: 1 }).then((data) => {
				this.customers = data
			})
		]).finally(() => {
			this.loading = false
		})
	}
}
</script>
